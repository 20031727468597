<template>
  <div class="jump">
    <!-- <p style="24px">
      正在跳转到支付页面，请稍候~
    </p> -->
    <img src="@/assets/images/loading.gif" alt="" />
  </div>
</template>

<script>
import {getRedirectUrl} from '@/api/api';
import config from "@/config";

// 分发器页面，  用户扫码后进入的第一个页面：
// 判断是否存在channelUserId, 如果存在则直接跳到 对应的支付页面，如果不存在则重定向到对应的页面。
export default {
  components: {
  },

  mounted() {

    console.log("正在跳转", this.$router.params)

    // eslint-disable-next-line no-constant-condition
    // if( true ){
    //   this.$router.push({name: "CashierAlipay"})
    //   return;
    // }


    // //TODO 需要获取到不同的商户的userId
    // if(channelUserId.getChannelUserId()){// 存在
    
    //   //重定向到对应的支付页面
    //   this.$router.push({name: wayCodeUtils.getPayWay().routeName})
    //   return ;
    
    // }

    const that = this;
    // 以下为不存在
    that.toGetRedirectUrl()
    // getRedirectUrl().then(res => {
    //   console.log("cacheToken", config.cacheToken)
    //   console.log("res", res)
    //   if (res.payOrderId) {
    //       const parameter = {
    //           // jsapi 默认使用聚合二维码支付
    //           Amount: res.amount, // 支付金额
    //           Waycode: res.wayCode, // 支付方式
    //           ClientIp: res.clientIp, // 客户端IP
    //           ExtParam: res.extParam, // 扩展参数
    //           Subject: res.subject, // 商品标题
    //           Body: res.body, // 商品描述
    //           NotifyUrl: res.notifyUrl ,// 回调地址
    //           MchNo: res.mchNo,// 商户号
    //           AppId: res.appId, // 应用ID
    //           MchOrderNo: res.mchOrderNo, // 订单编号
    //           Currency: res.currency,
    //           DivisionMode: res.divisionMode // 分账模式
    //       }
    //       // const OrdJsondata = JSON.stringify(parameter)
    //       getPayOrder(parameter).then(result => {
    //           console.log(result)
    //           console.log(res)
    //           // location.href = result;
    //       }).catch(result => {
    //           that.$router.push({name: config.errorPageRouteName, params: {errInfo: result.msg}})
    //       });
    //   } else {
    //       location.href = res;
    //   }
      
    // }).catch(res => {
    //   that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
    // });
  },
  methods: {

    toGetRedirectUrl() {
        const that = this;
        getRedirectUrl().then(res => {
            console.log("cacheToken", config.cacheToken)
            console.log("res", res)
            // if (res.payOrderId) {
                // this.toGetPayOrder(res);
            // } else {
                location.href = res
            // }
        }).catch(res => {
            that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
        });
    }

    // toGetPayOrder(result) {
    //     const that = this;
    //     const parameter = {
    //         // jsapi 默认使用聚合二维码支付
    //         Amount: result.amount, // 支付金额
    //         Waycode: result.wayCode, // 支付方式
    //         ClientIp: result.clientIp, // 客户端IP
    //         ExtParam: result.extParam, // 扩展参数
    //         Subject: result.subject, // 商品标题
    //         Body: result.body, // 商品描述
    //         NotifyUrl: result.notifyUrl ,// 回调地址
    //         MchNo: result.mchNo,// 商户号
    //         AppId: result.appId, // 应用ID
    //         MchOrderNo: result.mchOrderNo, // 订单编号
    //         Currency: result.currency,
    //         DivisionMode: result.divisionMode // 分账模式
    //     }
    //     getPayOrder(parameter).then(res => {
    //         console.log(result)
    //         console.log(res)
    //         // location.href = res;
    //     }).catch(res => {
    //         that.$router.push({name: config.errorPageRouteName, params: {errInfo: res.msg}})
    //     });
    // }
  }
}
</script>
<style lang="css" scoped>
  .jump {
    height:100vh;
    width: 100vw;
    display:flex;
    justify-content: center;
    align-items: center;
  }
</style>
